import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import { Menu, Tooltip } from "antd";
import { COLORS } from "../utils/colors";
import { sizes } from "../utils/sizes";
import { ApiMetadataType } from "../utils/apis";
import { EndpointType } from "../utils/utils";
import { keys } from "lodash";

export const SmallTitle = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.darkGray};
`;

export const MediumTitle = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.lightGray};
  font-weight: 500;
`;

export const BoldSecondaryText = styled.div`
  color: ${COLORS.lightGray};
  font-weight: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;

export const MediumTitleDarkGray = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.darkGray};
  font-weight: 400;
  line-height: 18px;
`;

export const MediumTitleDarkGrayH1 = styled.h1`
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.darkGray};
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0px;
`;

export const MediumTitleNearWhite = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.nearWhite};
  font-weight: 500;
`;

export const TitleNearWhite = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.nearWhite};
`;

export const SmallSecondaryText = styled.div`
  color: ${COLORS.darkGray};
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
`;

export const LargeTitleLightGray = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${COLORS.lightGray};
`;

export const TabMenu = styled(Menu)`
  font-size: 13px;
  line-height: 38px;
  background: ${COLORS.backgroundDarkGray};
  color: ${COLORS.nearWhite};
  border-bottom: none;

  .ant-menu-item-selected {
    color: ${COLORS.lightPurple} !important;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 44px;
  padding-left: 64px;

  @media (max-width: ${sizes.medium}px) {
    padding-left: 0px;
    padding: 18px 18px;
  }

  width: 100%;
  max-width: 1300px;
`;

export const generateUrl = (
  path: string,
  // only include if you want base URL to show up
  apiMetadata?: ApiMetadataType,
  pathParams?: {
    [key: string]: any;
  },
  serverVariablesData?: {
    [key: string]: any;
  }
) => {
  let basePath = apiMetadata?.servers?.[0]?.url || "";

  // Remove trailing slash if it exsits
  if (basePath.slice(-1) === "/") {
    basePath = basePath.slice(0, -1);
  }

  let url = `${basePath}${path}`;
  if (pathParams) {
    for (const paramKey of keys(pathParams)) {
      if (pathParams[paramKey] != null && pathParams[paramKey] != "") {
        url = url.replace(`{${paramKey}}`, `{${pathParams[paramKey]}}`);
      }
    }
  }
  const serverVariables = apiMetadata?.servers?.[0]?.variables;

  if (serverVariables && serverVariablesData) {
    for (const serverVariableKey of keys(serverVariables)) {
      if (
        serverVariablesData[serverVariableKey] != null &&
        serverVariablesData[serverVariableKey] != ""
      ) {
        url = url.replace(
          `{${serverVariableKey}}`,
          `{${serverVariablesData[serverVariableKey]}}`
        );
      }
    }
  }

  // color parts with {}
  const parts: any = url.split(/({.*?})/);
  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <span style={{ color: COLORS.lightPurple }} key={i}>
        {/* Remove the {} */}
        {parts[i].slice(1, -1)}
      </span>
    );
  }

  return <>{parts}</>;
};

const CustomButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid ${COLORS.brightDarkGrayBorder};
  background: ${COLORS.brightDarkGray};
  color: ${COLORS.lightGray};
  font-size: 12px;
`;

export const SecondaryButton = (
  props: {
    large?: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
  } & React.ComponentProps<typeof Button>
) => {
  const { children, style = {}, large } = props;

  return (
    <CustomButton
      {...props}
      type="ghost"
      size={large ? "large" : "small"}
      style={style}
    >
      {children}
    </CustomButton>
  );
};
